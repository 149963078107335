import { useEffect, useState } from 'react'

import { getCountries } from '../../apis/common.apis'
import { orgSize, sectorData } from '../organisation/store'
import { getOrgList } from './api'
import { policyOptions } from './store'

//policyDropdown

export const GetFilterDetails = () => {
  const [countryList, setCountryList] = useState<any>()
  const [organisationList, setOrganisationList] = useState<any>()
  const getCountry = async () => {
    const data = await getCountries()
    const input = data.map((item: any) => ({
      ...item,
      id: item.code,
      country_name: item.name,
      country_code: item.code,
    }))
    setCountryList(input)
    return input
  }
  const handleCountry = async (search: any) => {
    const input = countryList.filter(
      (item: any) =>
        !search ||
        item.country_name.toLowerCase().includes(search.toLowerCase())
    )
    return input
  }
  const handleOrganisationList = async () => {
    const data = await getOrgList()
    const input = data.map((item: any) => ({
      ...item,
      id: item.id,
      org_name: item.name,
    }))
    setOrganisationList(input)
    return input
  }
  const getOrganisationList = async (search: any) => {
    const input = organisationList.filter(
      (item: any) =>
        !search || item.org_name.toLowerCase().includes(search.toLowerCase())
    )
    return input
  }

  const organisationSize = (search: any) => {
    let data = orgSize
    if (search) {
      data = orgSize.filter((item) =>
        item.label.toLowerCase().includes(search.toLowerCase())
      )
    }
    return data
  }
  const getSector = (search?: any) => {
    let data = sectorData
    if (search) {
      data = sectorData.filter((item) =>
        item.name.toLowerCase().includes(search.toLowerCase())
      )
    }
    return data
  }
  const getTierData = (search?: string) => {
    const tierData = [
      {
        id: 'Diversity Mark Accredited',
        tier: 'Diversity Mark Accreditation',
        name: 'Diversity Mark Accreditation',
      },
      {
        id: 'Silver Accredited',
        tier: 'Silver Accreditation',
        name: 'Silver Accreditation',
      },
      {
        id: 'Gold Accredited',
        tier: 'Gold Accreditation',
        name: 'Gold Accreditation',
      },
    ]

    let data = tierData

    if (search) {
      data = tierData.filter((item) =>
        item.tier.toLowerCase().includes(search.toLowerCase())
      )
    }

    return data
  }

  useEffect(() => {
    getCountry()
    handleOrganisationList()
  }, [])
  return [
    {
      name: ' Organisation Name',
      type: 'multi_select',
      getData: getOrganisationList,
      descId: 'id',
      desc: 'org_name',
      async: true,
      isPrimary: true,
      slug: 'org_name',
      objectId: 'organisation_id',
    },
    {
      name: 'Accreditation Tier',
      type: 'multi_select',
      getData: getTierData,
      desc: 'name',
      descId: 'id',
      async: true,
      isPrimary: true,
      slug: 'stage',
      objectId: 'stage',
    },
    {
      type: 'date',
      desc: 'givenDate',
      isPrimary: true,
      name: 'Filter By Date',
      slug: 'givenDate',
    },
    {
      name: 'Organisation Sector',
      type: 'multi_select',
      getData: getSector,
      desc: 'sector',
      descId: 'id',
      async: true,
      isPrimary: true,
      slug: 'sector',
      objectId: 'sector',
    },
    {
      name: 'Organisation Size (Employees)',
      type: 'multi_select',
      getData: organisationSize,
      descId: 'name',
      desc: 'label',
      async: true,
      isPrimary: true,
      slug: 'size',
      objectId: 'size',
    },
    {
      name: 'Declared Headcount',
      type: 'multi_select',
      getData: organisationSize,
      descId: 'id',
      desc: 'value',
      async: true,
      isPrimary: true,
      slug: 'total',
      objectId: 'total',
    },

    {
      type: 'date_picker',
      selectRange: true,
      desc: 'fromDate',
      descSecondary: 'toDate',
      isPrimary: true,
      name: 'Join Date',
      slug: 'created_date',
    },
    {
      desc: 'policy',
      type: 'custom_search_select',
      descId: 'id',
      async: true,
      isPrimary: true,
      data: policyOptions,
      name: 'ED&I Strategy',
      slug: 'policy',
      objectId: 'has_dei_strategy',
    },
    // {
    //   desc: 'option',
    //   type: 'custom_search_select',
    //   descId: 'id',
    //   async: true,
    //   isPrimary: true,
    //   data: policyDropdown,
    //   name: 'Charter Signatory',
    //   slug: 'is_verified',
    //   objectId: 'is_verified',
    // },
    {
      name: 'Country',
      type: 'multi_select',
      getData: handleCountry,
      descId: 'id',
      desc: 'country_name',
      async: true,
      isPrimary: true,
      slug: 'country_code',
      objectId: 'country',
    },

    // {
    //   name: 'Postal Code',
    //   type: 'text_field',
    //   slug: 'pin_code',
    //   objectId: 'pin_code',
    //   isPrimary: true,
    //   descId: 'id',
    //   desc: 'pin_code',
    // },
    // {
    //   name: 'State',
    //   type: 'text_field',
    //   slug: 'state',
    //   objectId: 'state',
    //   isPrimary: true,
    //   descId: 'id',
    //   desc: 'state',
    // },
    // {
    //   name: 'City',
    //   type: 'text_field',
    //   slug: 'city',
    //   objectId: 'city',
    //   isPrimary: true,
    //   descId: 'id',
    //   desc: 'city',
    // },
  ]
}
