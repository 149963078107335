import { zodResolver } from '@hookform/resolvers/zod'
import moment from 'moment'
import { useCallback, useEffect, useState } from 'react'
import { ExpandableToolTip } from 'react-core-ts'
import { FormProvider, useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { ButtonDropdownItemProps } from '../../common/types'
import InfoBox from '../../components/app/alertBox/infoBox'
import { PrimaryFilterComponent } from '../../components/app/filterComponents/primaryFilter'
import Tabularform from '../../components/app/tabularform/TabularForm'
import { Button, DialogModal } from '../../components/common'
import { TagActions } from '../../components/common/emailChip'
import Icons from '../../components/common/icons'
import ListingHeader from '../../components/common/ListingTiles'
import { useSnackbarManager } from '../../components/common/snackbar'
import TabularLoader from '../../components/common/tabularLoader/index'
import { useClearFilter } from '../../store/filterSore/clearStore'
import { useAggregateReportFilterStore } from '../../store/filterSore/reportStore'
import {
  exportDataTable,
  exportOrgDataTable,
  getDataTables,
  getOrgDataTables,
} from './api'
import { GetFilterDetails } from './filterProperties'
import { TabualrDataSchema, tabularDataSchema } from './schema'
import { dataTableHeader } from './store'

type Col = 'uk' | 'global' | 'ireland'
// const listItems = [
//   {
//     name: 'Gender Age Breakdown',
//     type: 'engagement-age',
//     rowTitle: 'engagement',
//     descKey: 'engagement_age',
//   },
//   {
//     name: 'External Recruitment by Grade',
//     type: 'external-recruitment-by-grade-report',
//     rowTitle: undefined,
//     descKey: 'external_recruitment',
//   },
//   {
//     name: 'Attrition (Resignation) by Grade',
//     // type: 'attrition_by_grade',
//     type: 'attrition-by-grade-report',
//     rowTitle: undefined,
//     descKey: 'attrition',
//   },
//   {
//     name: 'Sickness Absence by Grade',
//     // type: 'sickness_absense_by_grade',
//     type: 'sickness-absense-by-grade-report',
//     rowTitle: undefined,
//     descKey: 'sickness',
//   },
//   {
//     name: 'Flexible Working Requests (Submitted & Approved)',
//     // type: 'flexible_working_requests_by_grade',
//     type: 'flexible-working-requests-by-grade-report',
//     rowTitle: undefined,
//     descKey: 'flexible_working',
//   },
//   {
//     name: 'Gender Breakdown (Legacy)',
//     type: 'gender-breakdown-report',
//     rowTitle: 'engagement',
//     descKey: 'gender_breakdown',
//   },
//   {
//     name: 'Age Breakdown (Legacy)',
//     type: 'age-breakdown-report',
//     rowTitle: 'age',
//     descKey: 'age_breakdown',
//   },
// ]

const listItems = [
  {
    title: 'Consolidated Data Summary',
    items: [
      {
        name: 'Gender Age Breakdown',
        type: 'engagement-age',
        rowTitle: 'engagement',
        descKey: 'engagement_age',
      },
      {
        name: 'External Recruitment by Grade',
        type: 'external-recruitment-by-grade-report',
        rowTitle: undefined,
        descKey: 'external_recruitment',
      },
      {
        name: 'Attrition (Resignation) by Grade',
        type: 'attrition-by-grade-report',
        rowTitle: undefined,
        descKey: 'attrition',
      },
      {
        name: 'Sickness Absence by Grade',
        type: 'sickness-absense-by-grade-report',
        rowTitle: undefined,
        descKey: 'sickness',
      },
      {
        name: 'Flexible Working Requests (Submitted & Approved)',
        type: 'flexible-working-requests-by-grade-report',
        rowTitle: undefined,
        descKey: 'flexible_working',
      },
    ],
  },
  {
    title: 'Legacy Data',
    items: [
      {
        name: 'Gender Breakdown (Legacy)',
        type: 'gender-breakdown-report',
        rowTitle: 'engagement',
        descKey: 'gender_breakdown',
      },
      {
        name: 'Age Breakdown (Legacy)',
        type: 'age-breakdown-report',
        rowTitle: 'age',
        descKey: 'age_breakdown',
      },
    ],
  },
]

type Props = {
  fromOrg?: boolean
}

type DataTableHeaderKey = keyof typeof dataTableHeader
const columnTitle: Col[] = ['uk', 'global', 'ireland']
let updateKey = 1
const ReportGeneration = (props: Props) => {
  const params = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [exportLoading, setExportIsLoading] = useState(false)
  const [isCollapse, setIsCollapse] = useState<Record<number, boolean>>({})

  const [tabularData, setTabularData] = useState<any>()
  const [lastUpdate, setLastUpdate] = useState<any>()
  const [rowKey, setRowKey] = useState<any>('engagement')
  const [descKey, setDescKey] = useState<DataTableHeaderKey>('engagement_age')
  const [sideItem, setSideItem] = useState<string>('Gender Age Breakdown')
  const [activeIndex, setActiveIndex] = useState<string>('engagement-age')
  const [edit, setEdit] = useState(false)

  const [exportPopup, setExportPopup] = useState(false)
  const [emailIds, setEmailIds] = useState<string[]>([])
  const [emailError, setEmailError] = useState('')
  console.log('rowKey', rowKey)
  console.log('lastUpdate', lastUpdate)
  const getMaxDate = () => {
    const today = new Date()
    return today // Allow selecting today's date
  }

  const maxDate = getMaxDate()
  console.log('maxDate', maxDate)

  const [givenDate, setGivenDate] = useState('')
  const { pageParams, setPageParams, setAdvanceFilter } =
    useAggregateReportFilterStore()
  const { filters, filterProps } = pageParams

  const methods = useForm<TabualrDataSchema>({
    resolver: zodResolver(tabularDataSchema),
    // defaultValues: { ...tabularData },
  })
  const searchParams = {
    ...filters,
  }
  const getDataTableDetails = async () => {
    // let input = {}
    // if (givenDate) {
    //   input = {
    //     given_date:
    //       givenDate === null ? null : moment(givenDate).format('YYYY-MM-DD'),
    //     // ? moment(new Date()).format('YYYY-MM-DD')
    //   }
    // }
    setIsLoading(true)
    const data = props.fromOrg
      ? await getOrgDataTables(activeIndex, searchParams)
      : await getDataTables(params?.id, activeIndex, searchParams)
    setTabularData(data.results)
    setLastUpdate(data.last_updated_on)
    setIsLoading(false)
    updateKey++
  }
  useEffect(() => {
    getDataTableDetails()
  }, [activeIndex])
  useEffect(() => {
    methods.reset(tabularData)
  }, [tabularData])

  const { enqueueSnackbar } = useSnackbarManager()
  const handleClear = useClearFilter()
  const handleSwitchTab = (item: any) => {
    handleClear()
    setActiveIndex(item.type)
    setRowKey(item.rowTitle)
    setEdit(false)
    setDescKey(item.descKey)
    setSideItem(item.name)
    setGivenDate('')
  }

  useEffect(() => {
    getDataTableDetails()
  }, [givenDate])

  // const handleDateChange = (data: any) => {
  //   setGivenDate(data.value)
  // }
  const handleExport = async () => {
    let input = {}
    if (emailIds.length > 0 && givenDate) {
      input = {
        given_date:
          givenDate === null
            ? moment(new Date()).format('YYYY-MM-DD')
            : moment(givenDate).format('YYYY-MM-DD'),
        is_email_sent: true,
        recipient_email: emailIds,
      }
    } else if (emailIds.length > 0) {
      input = {
        // given_date:
        //   givenDate === null
        //     ? moment(new Date()).format('YYYY-MM-DD')
        //     : moment(givenDate).format('YYYY-MM-DD'),
        is_email_sent: true,
        recipient_email: emailIds,
      }
    } else {
      setExportIsLoading(true)
      if (givenDate) {
        input = {
          given_date:
            givenDate === null
              ? moment(new Date()).format('YYYY-MM-DD')
              : moment(givenDate).format('YYYY-MM-DD'),
        }
      }
    }

    try {
      const data = props.fromOrg
        ? await exportOrgDataTable(activeIndex, input)
        : await exportDataTable(activeIndex, { ...searchParams, ...input })

      if (emailIds.length === 0) {
        const url = window.URL.createObjectURL(data)
        const currentDate = new Date()
        const formattedDate = moment(
          currentDate.toISOString().slice(0, 10)
        ).format('YYYY-MM-DD')
        const filename =
          activeIndex === 'engagement-age'
            ? `gender-age-breakdown_${formattedDate}.xlsx`
            : `${activeIndex}_${formattedDate}.xlsx`
        const a = document.createElement('a')
        a.href = url

        a.download = filename
        document.body.appendChild(a)
        a.click()

        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
        setExportIsLoading(false)
      } else {
        if (data) {
          enqueueSnackbar(
            'Your report is being processed and will be emailed shortly',
            { variant: 'success' }
          )
        }
      }
    } catch (error) {
      enqueueSnackbar('Failed to send data', { variant: 'error' })
    }
  }
  // type DataTableHeaderKey = keyof typeof dataTableHeader;
  // const headerKey:DataTableHeaderKey = activeIndex as DataTableHeaderKey
  const dropdown = {
    items: [
      {
        label: 'Export via Email',
        icon: 'mail',
        onClick: () => {
          console.log('Export via Email clicked')
        },
      },
    ],
    onClick: (item: ButtonDropdownItemProps) => {
      console.log(`onClick: ${item.label}`)
      setExportPopup(true)
    },
  }
  const handleEmailUpdate = (emails: string[]) => {
    setEmailError('')
    setEmailIds(emails)
  }

  const handleExportSubmit = () => {
    if (emailIds.length === 0) {
      setEmailError('Required')
    } else {
      handleExport()
      setEmailIds([])
      setExportPopup(false)
    }
  }

  const handleExportCancel = () => {
    setEmailError('')
    setEmailIds([])
    setExportPopup(false)
  }

  const basicData = {
    title: 'Administrator Reports',
    // status:
  }

  const filterDetails = GetFilterDetails()

  useEffect(() => {
    if (pageParams?.filterProps?.fromDate && !pageParams?.filterProps?.toDate) {
      return
    }
    getDataTableDetails()
  }, [pageParams])

  const handleFilterChange = (dta: any, name: string, id: string) => {
    const currentParams =
      useAggregateReportFilterStore.getState()?.pageParams || {}

    const getDefaultFilters = () => ({
      ...currentParams.filters,
      [id]: dta?.id ?? undefined,
    })

    const getDefaultFilterProps = () => ({
      ...currentParams.filterProps,
      [name]: dta[name] ?? undefined,
    })
    const filters = getDefaultFilters()
    const filterProps = getDefaultFilterProps()

    setPageParams({
      ...currentParams,
      page: 1,
      filters,
      filterProps,
    })
  }
  const handleCreatedDatepicker = useCallback(() => {
    setPageParams({
      ...pageParams,
      filters: {
        ...filters,
        fromDate: filterProps?.fromDate
          ? moment(filterProps?.fromDate).format('YYYY-MM-DD')
          : '',
        toDate: filterProps?.toDate
          ? moment(filterProps.toDate).format('YYYY-MM-DD')
          : '',
      },
    })
  }, [filterProps.toDate])
  useEffect(() => {
    if (
      filterProps.toDate ||
      (filterProps.toDate === null && filterProps.fromDate === null)
    ) {
      handleCreatedDatepicker()
    }
  }, [filterProps.toDate])
  const handleFilterDateChange = (event: any, desc: any, desc2: any) => {
    setPageParams({
      ...pageParams,
      filterProps: {
        ...filterProps,
        [desc]: event?.value[0],
        [desc2]: event?.value[1],
      },
    })
  }
  const handleDateChange = (event: any, desc: any) => {
    setPageParams({
      ...pageParams,
      filterProps: {
        ...filterProps,
        [desc]: event?.value,
      },
      filters: {
        ...filters,
        given_date: event?.value
          ? moment(event?.value).format('YYYY-MM-DD')
          : '',
      },
    })
  }
  const toggleCollapse = (index: number) => {
    setIsCollapse((prev) => ({
      ...prev,
      [index]: !prev[index],
    }))
  }
  return (
    <>
      <div>
        <ListingHeader data={basicData} />
      </div>
      <DialogModal
        isOpen={exportPopup}
        onClose={() => handleExportCancel()}
        title={'Send Via Email'}
        onSubmit={() => handleExportSubmit()}
        secondaryAction={() => handleExportCancel()}
        secondaryActionLabel="Cancel"
        actionLabel={'Send'}
        // actionLoader={loader}
        className="z-50"
        body={
          <>
            <InfoBox content="Enter the recipient’s email address in the field above and press ‘Enter’ to confirm. You can add multiple email addresses if needed. Once you’re done, click ‘Send’ to proceed or ‘Cancel’ to go back." />

            <p className="my-2 labels label-text">{'Email ID'}</p>
            <div className="flex flex-col label-badges">
              <TagActions
                onUpdate={handleEmailUpdate}
                error={emailError}
                setError={setEmailError}
              />
            </div>
          </>
        }
      />
      <div className="flex items-start gap-4 p-4 w-full">
        {/* <div className="bg-white rounded-md p-2 flex flex-col items-start w-[320px] shrink-0  sticky top-0">
          <div onClick={()=>setIsCollapse(!isCollapse)} className="flex gap-2 cursor-pointer justify-between mb-1 items-center border border-formBorder text-blackAlt py-1.5 px-2 text-common w-full rounded-xs">
            Consolidated Data Summary
            <Icons name="drop-arrow" className="cursor-pointer" />
          </div>
          {!isCollapse && 
          <ul className="flex flex-col gap-1 w-full">
            {listItems.map((item, index) => (
              <>
                <li
                  key={index}
                  className={`p-2  text-sm font-medium items-center flex justify-between gap-4 ${
                    activeIndex === item.type
                      ? 'bg-cardWrapperBg text-primary '
                      : 'text-blackAlt cursor-pointer'
                  }`}
                  onClick={() => handleSwitchTab(item)}
                >
                  {item.name}
                  <ExpandableToolTip
                    enabled
                    title={
                      <>
                        {item.type === 'gender-breakdown-report' ||
                        item.type === 'age-breakdown-report' ? (
                          <div className="text-xxs text-left p-1.5 text-nowrap">
                            <div>Mandatory for all Submissions </div>
                          </div>
                        ) : (
                          <div className="text-xxs text-left p-1.5 text-nowrap">
                            <div>Recommended for all Submissions</div>
                            <div>Mandatory for Gold</div>
                          </div>
                        )}
                      </>
                    }
                  >
                    <Icons
                      className="iconSize-large cursor-pointer"
                      name="exclamation-circle"
                    />
                  </ExpandableToolTip>
                </li>
                {item.name ===
                  'Flexible Working Requests (Submitted & Approved)' && (
                  <hr className="border-formBorder" />
                )}
              </>
            ))}
          </ul>
          }
        </div> */}
        <div className="sticky top-0 ">
          {listItems.map((section, sectionIndex) => (
            <div
              key={sectionIndex}
              className="bg-white rounded-md p-2 flex flex-col items-start w-[320px] shrink-0 mb-2"
            >
              <div
                onClick={() => toggleCollapse(sectionIndex)}
                className="flex gap-2 font-semibold cursor-pointer justify-between mb-1 items-center border-formBorder text-blackAlt py-1.5 px-2 text-common w-full rounded-xs"
              >
                {section.title}
                <div
                  className={`${isCollapse[sectionIndex] ? 'rotate-180' : ''}`}
                >
                  <Icons name="drop-arrow" className="cursor-pointer" />
                </div>
              </div>
              {/* Items List */}
              {!isCollapse[sectionIndex] && (
                <ul className="flex flex-col gap-1 w-full ps-3">
                  {section.items.map((item, itemIndex) => (
                    <li key={itemIndex} className="w-full">
                      <div
                        className={`p-2 text-xs items-center  flex rounded-sm justify-between gap-4 ${
                          activeIndex === item.type
                            ? 'bg-cardWrapperBg text-primary'
                            : 'text-blackAlt cursor-pointer'
                        }`}
                        onClick={() => handleSwitchTab(item)}
                      >
                        {item.name}
                        <ExpandableToolTip
                          enabled
                          title={
                            <>
                              {item.type === 'gender-breakdown-report' ||
                              item.type === 'age-breakdown-report' ? (
                                <div className="text-xxs text-left p-1.5 text-nowrap">
                                  <div>Mandatory for all Submissions</div>
                                </div>
                              ) : (
                                <div className="text-xxs text-left p-1.5 text-nowrap">
                                  <div>Recommended for all Submissions</div>
                                  <div>Mandatory for Gold</div>
                                </div>
                              )}
                            </>
                          }
                        >
                          <Icons
                            className="iconSize-large cursor-pointer"
                            name="exclamation-circle"
                          />
                        </ExpandableToolTip>
                      </div>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
        </div>

        <div className="flex flex-col gap-4 w-full overflow-auto">
          {isLoading ? (
            <TabularLoader />
          ) : (
            <div className="  bg-white rounded-md p-4 flex gap-4  items-center justify-between">
              <div className="flex gap-2 w-full">
                <Icons className="iconSize-large" name="exclamation-circle" />
                <div className="flex gap-1 flex-col">
                  <h4 className="text-primary text-sm font-bold mb-1">
                    {dataTableHeader[descKey]?.headerdesc}
                  </h4>

                  <p className="text-blackAlt text-xxs leading-4">
                    {dataTableHeader[descKey]?.desc}
                  </p>
                </div>
              </div>

              {!edit ? (
                <>
                  <div className="flex items-center gap-1">
                    {/* <label className="labels label-text textStyle">
                      Filter By
                    </label>
                    <div className="custom-date-picker ml-0">
                      <CustomDatePicker
                        placeholder={'Select Date'}
                        maxDate={maxDate}
                        name={'givenDate'}
                        value={givenDate ?? ''}
                        onChange={(value: any) => handleDateChange(value)}
                      />
                    </div> */}
                  </div>

                  <Button
                    label="Export"
                    icon="upload"
                    outlined={true}
                    onClick={handleExport}
                    isLoading={exportLoading}
                    dropdown={dropdown}
                  />
                </>
              ) : (
                ''
              )}
            </div>
          )}
          <div className="field-container p-4 bg-white rounded-md">
            <PrimaryFilterComponent
              filterParams={filterDetails}
              handleFilterChange={handleFilterChange}
              filterProps={filterProps}
              isPrimary={true}
              setAdvanceFilter={setAdvanceFilter}
              handleFilterDateChange={handleFilterDateChange}
              handleDateChange={handleDateChange}
              showMore={true}
            />
          </div>
          <div className="bg-white rounded-md">
            <FormProvider {...methods}>
              <div className="tabular-form">
                <Tabularform
                  tabularData={tabularData}
                  key={updateKey}
                  columnTitle={columnTitle}
                  sideItem={sideItem}
                  groupedColumns={true}
                  editable={edit}
                  hideEdit={true}
                />
              </div>
            </FormProvider>
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportGeneration
